<template>
    <!-- <h5 class="mb-0 px-4 py-3">Charts</h5>
      <v-divider class="my-0"></v-divider> -->
  
    <v-row class="px-5 product-row">
  
      <!-- <pre>{{ parentdetails.purchase_orders }}</pre> -->
      <!-- Supplier -->
      <!-- <v-col md="12" class="pt-1">
        <p class="mb-0 h4 font-weight-bold">Supplier</p>
      </v-col> -->
      <!-- <v-col md="12"  >
        <v-simple-table class="bt-table" style="border: 2px solid #f5f5f5">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center th" width="200">Supplier</th>
                <th class="text-center th" width="50">Price</th>
                <th class="text-center th" width="50">UOM Measure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center th">Nestlé Global. ()</td>
                <td class="text-center th">0.00</td>
                <td class="text-center th">CAN</td>
              </tr>
              <tr style="background-color: #f5f5f5">
                <td class="text-center th">Nestlé Global. ()</td>
                <td class="text-center th">12.00</td>
                <td class="text-center th">CTN</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col> -->
      <v-col md="12" class="" v-if="purchaseArr.length">
        <v-simple-table
          fixed-header
          style="height: calc(100vh - 280px)"
          class="bt-table product-simple-table"
        >
          <template v-slot:default>
            <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
              <tr>
                <th
                  v-for="(supplier_h, index) in supplierTableHeaders"
                  :key="'$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading"
                  :style="{ maxWidth: `${supplier_h.width}px` }"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="d-flex align-center" v-on="on" v-bind="attrs">
                        <template v-if="supplier_h.heading === 'primary_sales_price'">
                          <v-icon color="blue">mdi-circle</v-icon>
                        </template>
                        <template v-if="supplier_h.heading === 'secondary_purchase_price'">
                          <v-icon color="red">mdi-circle</v-icon>
                        </template>
                        <p class="text-truncate mb-0">{{ supplier_h.title }}</p>
                      </div>
                    </template>
                    <span class="h5 text-center">{{ supplier_h.title }}</span>
                  </v-tooltip>
                </th>
              </tr>
            </thead>
  
            <tbody v-if="!pageLoading">
              <template v-if="mod_Suppliers.length">
                <tr
                  :class="index % 1 === 0 && '#fff'"
                  v-for="(row, key, index) in purchaseArr"
                  class="product-listing-tr"
                  :key="'k_' + index"
                >
                  <td
                    style="border-right: none !important"
                    :width="data_headings.width"
                    v-for="(data_headings, idx) in supplierTableHeaders"
                    :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                    class="px-2"
                  >
                    <div
                      class="d-flex justify-center align-center"
                      v-if="data_headings.heading === 'supplier_status'"
                    >
                      <v-switch
                        color="green"
                        :value="false"
                        :ripple="false"
                        hide-details
                        inset
                        disabled="true"
                        class="mt-0"
                        v-model="row[data_headings.heading]"
                      ></v-switch>
                    </div>
                    <div
                      class="d-flex justify-center align-center"
                      v-else-if="
                        data_headings.heading === 'primary_sales_price' ||
                        data_headings.heading === 'secondary_purchase_price'
                      "
                    >
                      {{ formatMoneyVal(row[data_headings.heading]) }}
                    </div>
                    <p v-else class="mb-0 h5 px-6">
                      <template v-if="data_headings.heading == 'barcode'">
                        {{ row[data_headings.heading] }}
                      </template>
                      <template v-if="data_headings.heading == 'supplier'">
                        <div>
                        <v-icon color="disabled" size="18" class="me-1"
                          >mdi-home-city-outline</v-icon
                        >
                        <span
                          ><b>{{ row.supplier }}</b></span
                        >
                      </div>
                      <!-- <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-account</v-icon>
                        <b></b>
                      </div> -->
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-email</v-icon>
                        <span @click.stop.prevent="copyText('john@gmial.com')">{{
                          row.email
                        }}</span>
                      </div>
                      <div>
                        <v-icon color="disabled" size="18" class="me-1">mdi-phone</v-icon>
                        <span @click.stop.prevent="copyText('+65 1234 5678')">{{ row.phone }}</span>
                      </div>
                      </template>
                      <template v-else-if="data_headings.heading == 'total'">
                        {{ formatMoneyVal(row[data_headings.heading]) }}
                      </template>
                      <template v-else-if="data_headings.heading == 'bill'">
                        <!-- {{ (row[data_headings.heading]) }} -->
                        <div  v-if="row.bill">
                          {{ row.bill }}
  
                        </div>
                        <div v-else>
                          Not Billed
                        </div>
                      </template>
                      <template v-else-if="data_headings.heading == 'status'">
                        <!-- {{ row[data_headings.heading] }}
                        {{ row }} -->
                        <v-chip
                          v-if="!row.bill && row.status == 'Pending'"
                          small
                          outlined
                          label
                          :color="row.amountStatus == 'Completed' ? 'success' : 'red'"
                          >Initial Pending</v-chip
                        >
                        <v-chip
                          v-else-if="row.status == 'Initial Paid'"
                          small
                          outlined
                          label
                          :color="row.amountStatus == 'Completed' ? 'success' : 'indigo'"
                          >Initial Paid</v-chip
                        >
                      
                        <v-chip
                          v-else-if="row.bill && row.status == 'Pending'"
                          small
                          outlined
                          label
                          :color="row.amountStatus == 'Completed' ? 'success' : 'orange'"
                          >Final Pending</v-chip
                        >
                        <v-chip
                          v-else-if="row.bill && row.status == 'Paid'"
                          small
                          outlined
                          label
                          :color="row.amountStatus == 'Completed' ? 'success' : 'green'"
                          >Final Paid</v-chip
                        >
                      </template>
                    
                      <template v-else-if="data_headings.heading == 'payment_mode'">
                        <!-- {{ row[data_headings.heading] }} -->
                        <div v-if="row.payment_mode">
                          {{ row.payment_mode }}
  
  
                        </div>
                        <div v-else>
                          No Payment
                        </div>
                      </template>
                      <template v-else-if="data_headings.heading == 'date'">
                        {{ formatedateTime(row[data_headings.heading]) }}
                      </template>
                      <span v-if="row[data_headings.title.toLowerCase() + '_uom']">
                        / {{ row[data_headings.title.toLowerCase() + "_uom"] }}
                      </span>
                      
                    </p>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col v-else md="12" class="text-center mt-4">
        <p class="m-0 text-center black--text text-h6">
          <img
            width="30"
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mr-4"
          />
          Uhh... There are no data available at the moment.
        </p>
      </v-col>
    </v-row>
  </template>
  
  <script>
  import { formatMoney } from "accounting-js";
  import moment from "moment-timezone";
  
  export default {
    name: "product-chart",
    title: "Chart Product",
    props: {
        purchaseArr: {
        type: Object,
        default: () => {
          return [];
        },
      },
    },
  
    data() {
      return {
        supplierTableHeaders: [
          // { title: "Date", heading: "date", width: 150, uom: "" },
  
          { title: "PO #", heading: "barcode", width: 150, uom: "" },
          { title: "Supplier ", heading: "supplier", width: 150, uom: "" },
          { title: "Bill", heading: "bill", width: 150, uom: "" },
          { title: "Primary Mode", heading: "payment_mode", width: 150, uom: "" },
          { title: "PO Status", heading: "status", width: 150, uom: "" },
          { title: "Total", heading: "total", width: 150, uom: "" },
        ],
        product: {
          suppliers: [
            {
              id: 1,
              supplier_name: "Nestlé Global. ()",
              supplier_part: "Part",
              supplier_status: "Active",
              primary_sales_price: "48.00",
              secondary_purchase_price: "30.00",
              primary_uom: "CAN",
              secondary_uom: "CTN",
            },
          ],
        },
      };
    },
    methods: {
      formatedateTime(param) {
        return moment(param).format("DD/MM/YY ");
      },
  
      formatMoneyVal(money) {
        return formatMoney(money, {
          symbol: "RM",
          decimal: ".",
          thousand: ",",
          precision: 2,
          format: "%s%v",
        });
      },
    },
    computed: {
      mod_Suppliers() {
        return this.product.suppliers;
      },
    },
    mounted() {},
  };
  </script>
  
  <style scoped>
  .product-row {
    margin: 0 !important;
  }
  </style>
  