<template>
  <v-container fluid class="pt-0">
    <v-row class="vouchers pt-6">
      <v-col md="12" v-if="loading" class="text-center p-10">
        <v-progress-linear
          indeterminate
          height="6"
          rounded
          color="blue darken-4"
        ></v-progress-linear>
      </v-col>
      <template v-else-if="Logs.length">
        <v-simple-table fixed-header class="bt-table table_height_sales w-100">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="pa-2" width="20%">MODULE</th>
                <th class="pa-2" width="20%">DESCRIPTION</th>
                <th class="pa-2" width="20%">ADDED AT</th>
              </tr>
            </thead>
            <tbody v-if="!pageLoading">
              <tr v-for="(row, index) in Logs" :key="index">
                <td class="simple-table-td text-capitalize px-2">{{ row.module }}</td>
                <td class="simple-table-td px-2 text-capitalize">{{ row.description }}</td>
                <td class="simple-table-td px-4 text-capitalize">
                  {{ formatedateTimes(row.added_at) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <v-col v-else md="12" class="text-center">
        <p class="m-0 text-center black--text text-h5">
          <img
            width="50"
            :src="$assetURL('media/error/empty.png')"
            class="row-not-found-image mr-4"
          />
          Uhh... There are no logs available at the moment.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { SET_ERROR } from "@/core/services/store/common.module";
import { COMMON_EVENT_BUS } from "@/core/lib/common.lib";
import moment from "moment-timezone";
export default {
  props: {
    logos: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      loading: true,
      vouchers: [],
      Logs: [],
    };
  },
  watch: {
    logos: {
      deep: true,
      immediate: true,
      handler(param) {
        console.log(param, "persons");
        this.Logs = param;
      },
    },
  },
  methods: {
    getExpiredVouchers() {
      try {
        this.vouchers = null;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.loading = false;
      }
    },
    formatedateTimes(param) {
      return moment(param).format("DD/MM/YYYY hh:mm A");
    },
  },
  mounted() {
    const _this = this;

    _this.getExpiredVouchers();

    COMMON_EVENT_BUS.$on("orders:loaded", function () {
      _this.getExpiredVouchers();
    });
  },
};
</script>
<style scoped>
tr:nth-child {
  background-color: #fff;
}
</style>
