<template>
  <v-layout class="custom-phone-input">
    <v-flex class="country-code">
      <v-select dense flat outlined v-model="countryCode" :items="countryList" hide-details color="#007bff"
        :disabled="disabled" :loading="loading" item-text="name" item-value="iso2" item-color="#007bff" return-object
        v-on:change="updateActiveCountry">
        <template v-slot:selection>
          <div :class="activeCountry.iso2.toLowerCase()" class="vti__flag mr-2" />
        </template>
        <template v-slot:item="data">
          <span :class="data.item.iso2.toLowerCase()" class="vti__flag mr-2" />
          <span>{{ data.item.name }} {{ `+${data.item.dialCode}` }}</span>
        </template>
      </v-select>
    </v-flex>
    <v-flex class="width-100">
      <v-text-field :key="phoneKey" :id="id" outlined @paste="onPaste" v-model="phone" :rules="rules" dense
        :placeholder="placeholder" :disabled="disabled" :loading="loading || numberLoading" flat color="#007bff"
        :hide-details="hideDetails" v-on:keyup="
          $emit('input', phone);
        $emit('change', phone);
        validateNumber(phone);" @keydown="prevention($event, phone)" v-on:change="
  $emit('input', phone);

$emit('change', phone);
validateNumberMala(phone);
" v-on:blur="
  $emit('input', phone);

$emit('change', phone);
" v-on:focus="
  $emit('change', phone);
">
      </v-text-field>
      <template v-if="invalidNumber">
        <span style="color: red; font-weight: 600">{{ invalidNumber }}</span>
      </template>
    </v-flex>
  </v-layout>
</template>
<style src="@/assets/sass/sprite.scss" lang="scss"></style>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { selectedCountries } from "@/core/plugins/country-list";
// import LocalService from "@/core/services/local.service";
import { QUERY } from "@/core/services/store/request.module";
import { find, replace, split, isBoolean } from "lodash";

export default {
  mixins: [ValidationMixin],
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "phone",
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Phone Number",
    },
    label: {
      type: String,
      default: "Phone Number",
    },
    phoneKey: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    value(param1, param2) {
      if (param1 != param2) {
        this.setValues();
      }
    },
    phone: {
			handler(value) {
				if (value) {
					let cc = value.includes("+");
					if (!cc) {
						this.phone = `+${this.countryCode.dialCode}-${this.phone}`;
					}
				}
			},
		},
  },
  data() {
    return {
      phone: null,
      validateNumberCheck: null,
      invalidNumber: null,
      numberLoading: false,
      countryCode: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
        phone_character_limit: 8,
      },
      activeCountry: {
        areaCodes: null,
        dialCode: "65",
        iso2: "SG",
        name: "Singapore",
        priority: 0,
        phone_character_limit: 8,
      },
      phoneMask: null,
      countryList: [],
      // allCountries: allCountries,
    };
  },
  methods: {
    onPaste(evt) {
      let text = evt.clipboardData.getData('Text')
      if (!isBoolean(+text)) {
        evt.preventDefault();
      }
    },
    prevention(evt, phone) {
      let s = toString(phone).slice(4);
      let charCode = evt.keyCode || evt.which;
      let pp = this.countryCode.dialCode == 65 ? s.length < 8 : s.length < 12;
      console.log({ pp })
      if (((charCode > 47 && charCode < 58) || (charCode > 95 && charCode < 106) || charCode == 8)) {
        return false
      } else {
        evt.preventDefault();
      }
    },
    validateNumber(number) {
      if (number && number.length) {
        let maxLimit = Number(number.length) - 4;
        if (number && this.countryCode.phone_character_limit == maxLimit) {
          const _this = this;
          this.numberLoading = true;
          let apiKey = process.env.VUE_APP_MOBILE_KEY;
          _this.$store
            .dispatch(QUERY, {
              url: "https://micro.businessthrust.com/api/check-mobile",
              data: {
                mobile: number,
                apikey: apiKey,
              },
            })
            .then(({ data }) => {
              this.validateNumberCheck = data.result;
              //console.log(this.validateNumberCheck)
              if (
                this.validateNumberCheck &&
                !this.validateNumberCheck.valid &&
                this.validateNumberCheck.line_type == "N/A"
              ) {
                // (this.phone = null),
                (this.invalidNumber = "Invalid Number");
              } else {
                this.invalidNumber = null;
              }
            })
            .catch((error) => {
              _this.logError(error);
            }).finally(() => {
              this.numberLoading = false;
            });
        }
      }



    },
    validateNumberMala(number) {
      if (number) {
        const _this = this;
        let apiKey = process.env.VUE_APP_MOBILE_KEY;
        this.numberLoading = true;
        _this.$store
          .dispatch(QUERY, {
            url: "https://micro.businessthrust.com/api/check-mobile",
            data: {
              mobile: number,
              apikey: apiKey,
            },
          })
          .then(({ data }) => {
            this.validateNumberCheck = data.result;
            if (
              this.validateNumberCheck &&
              !this.validateNumberCheck.valid &&
              this.validateNumberCheck.line_type == "N/A"
            ) {
              // (this.phone = null),
              (this.invalidNumber = "Invalid Number");
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            this.numberLoading = false;
          });
      }
    },

    getActiveCountries() {
      this.countryList = selectedCountries; // LocalService.getData("phone_countries");
      if (this.countryList && this.countryList.length) {
        this.countryCode = find(this.countryList, {
          dialCode: "65",
        });
        if (!this.countryCode) {
          this.countryCode = this.countryList[0];
        }
      }
    },
    setValues() {
      const value = replace(this.value, "+", "");
      const phoneCode = split(value, "-", 1);
      if (phoneCode && phoneCode[0]) {
        const countryCode = find(this.countryList, {
          dialCode: phoneCode[0],
        });

        if (countryCode) {
          this.countryCode = countryCode;
          this.activeCountry = countryCode;
        }
      }
      this.phone = this.value ? this.value : null;
      this.$nextTick(() => {
        this.phone = this.value ? this.value : null;
      });
    },
    updateActiveCountry() {
      const dialCode = this.countryCode?.dialCode
      const phone_character_limit = this.countryCode?.phone_character_limit
      if (dialCode) {
        var str = new Array(phone_character_limit + 1).join("#");
        this.phoneMask = `+${dialCode}-${str}`;
        //  if (dialCode == "65") {
        //   this.phoneMask = `+${dialCode}-########`;
        // } else {
        //   this.phoneMask = `+${dialCode}-############`;
        // } 
      }
      this.phone = null;
      this.activeCountry = this.countryCode;
      this.$nextTick(() => {
        this.phone = null;
      });
      this.invalidNumber = null;
    },
  },
  computed: {
    rules() {
      const dialCode = this.countryCode?.dialCode;
      if (!dialCode) {
        return [];
      }
      /*   let maxLimitss =
      dialCode.length + 2 + this.activeCountry.phone_character_limit; */

      const maxLimit = dialCode.length + 2 + 14
      const minLimit = dialCode.length + 2 + 8
      //const maxLimit = dialCode == "65" ? 12 : 15;
      //const minLimit = dialCode == "65" ? 12 : 12;

      const rule = [
        this.validateRules.minLength(this.phone, "number", minLimit),
        this.validateRules.maxLength(this.phone, "number", maxLimit),
      ];
      if (this.required) {
        rule.push(this.validateRules.required(this.phone, "number"));
      }
      return rule;
    },
  },
  mounted() {
    this.getActiveCountries();

    this.updateActiveCountry();
    this.setValues();
  },
};
</script>
